<template>
  <div class="content">
    <h1 class="title">Международные федерации</h1>

    <div v-if="isEditor" class="buttons">
      <b-button type="is-success" @click="handleAdd"> Добавить </b-button>
    </div>

    <b-table
      :data="federations"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :mobile-cards="false"
    >
      <b-table-column v-slot="props" label="#">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column v-slot="props" field="title" label="Название" sortable>
        <router-link
          :to="{
            name: 'InternationalFederationShow',
            params: { id: props.row.id },
          }"
          exact
        >
          {{ props.row.title }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="code" label="Код" sortable>
        {{ props.row.code }}
      </b-table-column>

      <b-table-column v-slot="props" field="siteUrl" label="Сайт">
        {{ props.row.siteUrl }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InternationalFederationForm from "./InternationalFederationForm";
import { Resource } from "@/common/const/common";

const defaultFederation = {
  title: "",
  code: "",
  siteUrl: "",
};

export default {
  name: "InternationalFederationsIndex",

  data() {
    return {
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      federations: [],
    };
  },

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },
  async mounted() {
    await this.loadData();
    document.title = "Международные федерации";
  },

  methods: {
    async closeForm(isAdded = true) {
      if (isAdded) {
        await this.loadData();
      }
    },

    async handleAdd() {
      this.$buefy.modal.open({
        parent: this,
        component: InternationalFederationForm,
        props: { federation: { ...defaultFederation } },
        events: { close: this.closeForm },
        hasModalCard: true,
      });
    },

    async loadData() {
      try {
        this.federations = await this.$api.fetchData.get(
          `${Resource.INTERNATIONAL_FEDERATIONS}?_sort=title:asc`
        );
      } catch (e) {
        await this.$router.push("/not-found");
      }
    },
  },
};
</script>
